import Image from 'next/image'
import Link from 'next/link'


import { useLogEvent } from '@/utils/analytics';
import Metadata from '@/utils/metadata';

const Home = () => {
    const logEvent = useLogEvent();

    return (
        <>
            <div className="flex flex-col justify-between w-full h-screen flex-1">
                <div className="flex flex-col text-center xl:pb-8 justify-end items-center relative w-full h-full">

                    <Image
                        priority
                        fill={true}
                        src="/assets/backgrounds/hero-bg.webp"
                        className="absolute top-0 xl:-top-8 object-cover w-full min-h-[600px] h-auto -z-10"
                        alt="House in the forest"
                    />

                    <h1 className="font-aleo max-w-[20ch] font-bold text-2xl sm:text-4xl md:text-5xl xl:text-6xl drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)] text-white mt-20">
                        {"Improving storytelling through human <> AI collaboration."}
                    </h1>
                    <div className="flex flex-col justify-end h-1/2 w-full">
                        <div className="flex flex-col py-4 2xl:pb-12 items-center justify-between h-1/2 w-full">
                            <Link
                                onClick={() => logEvent("landing_begin_story_clicked")}
                                href="/create-story"
                                className="btn-primary lg:px-8 lg:py-4">
                                Begin My Story
                            </Link>
                        </div>
                    </div>
                    <footer className="relative flex flex-col gap-4 items-center w-full py-4 md:py-6 xl:py-8">
                        <nav className="text-sm sm:text-base flex gap-4 justify-center items-center flex-wrap">
                            <Link href="/privacy-policy" className="link-w-ul after:bg-black">
                                Privacy Policy
                            </Link>
                            <Link href="/chatgpt-stories-plugin" className="link-w-ul after:bg-black">
                                ChatGPT Stories Plugin
                            </Link>
                            <Link href="/about" className="link-w-ul after:bg-black">
                                About Us
                            </Link>
                            <Link href="/contact-us" className="link-w-ul after:bg-black">
                                Contact Us
                            </Link>
                        </nav>
                        <div className="text-xs sm:text-sm">&copy; 2023 StoryBird.ai</div>
                    </footer>
                </div>
            </div>

            <Metadata
                title="Improving storytelling through human <> AI collaboration."
                description="StoryBird.ai is a leading AI Writing platform. We help you write, illustrate, edit, and publish stories fueled by generative AI."
            />
        </>
    );
}

export default Home;