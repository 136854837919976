import Head from 'next/head';

import { env } from "./env";
export interface MetaProps {
    title: string;
    description: string;
    img?: string;
    imgWidth?: number;
    imgHeight?: number;
    canonicalLink?: string;
    creator?: string;
    noIndex?: boolean;
}

export default function Metadata(data: MetaProps) {
    const { title, description, creator, noIndex } = data;
    const canonicalLink = data.canonicalLink? env("location") + data.canonicalLink : null;
    const image = data.img ? env("imageKit") + data.img : env("location") + '/assets/logos/storybird/logo.webp';
    const favicon = data.img ? env("imageKit") + data.img : env("location") + '/favicon.ico';
    const imgHeight = data.imgHeight?.toString() || '630';
    const imgWidth = data.imgWidth?.toString() || '1200';

    return (
        <Head>
            <title>{title}</title>
            <link rel="icon" href={favicon} />
            {noIndex && <meta name="robots" content="noindex" />}
            {canonicalLink && <link rel="canonical" href={canonicalLink} />}
            {canonicalLink && <meta property="og:url" content={canonicalLink} />}
            <meta name="description" content={description} />
            <meta itemProp="image" content={image} />
            <meta name="twitter:card" content="summary_large_image" />
            {canonicalLink && <meta name="twitter:url" content={canonicalLink} />}
            <meta name="twitter:site" content="@storybird" />
            {creator && <meta name="twitter:creator" content={`@${creator}`} />}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" itemProp="image" content={image} />
            {imgWidth && <meta property="og:image:width" content={imgWidth} />}
            {imgHeight && <meta property="og:image:height" content={imgHeight} />}
        </Head>
    );
}